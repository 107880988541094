
.align-left
  text-align: left

  img
    margin-left: 0
    margin-right: auto

.align-center
  text-align: center

  img
    margin-left: auto
    margin-right: auto

.align-right
  text-align: right

  img
    margin-left: auto
    margin-right: 0

.align-justify
  text-align: justify

  img
    width: 100%
    margin-left: auto
    margin-right: auto
